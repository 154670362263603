import React, { useContext, useState } from "react";
import Badge from "@material-ui/core/Badge";
import AppContext from "../providers/AppContext";
import Home from "./Home";
import steillogo from "../steillogo.jpg";

import { useHistory } from "react-router-dom";



export default function Start() {
  const history = useHistory();
  function handleClick() {
    history.push("/impressum");
  }

  return (
    <div>
      <p>Willkommen auf der Homepage des Gestüts Steilfels!</p>

      <img className="logos" src={steillogo} alt="Redouane" />

      <p className="impress" onClick={handleClick}>Impressum</p></div>

  );
}
