import React, { useState } from "react";
import StammComp from "./StammComp";
import { makeStyles } from "@material-ui/core/styles";
import qrcode from "../p1.jpg";
import pzwei from "../p2.png";
import pdrei from "../p3.jpg";


import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));

export default function Generation() {
    const classes = useStyles();
    const p1 = [
        {
            name: "Gulab",
            geschlecht: "weiblich",
            birthdate: "11.01.2020",
            father: "Grand",
        },
        {
            name: "Nihal",
            geschlecht: "männlich",
            birthdate: "01.02.2018",
            father: "Grand",
        },
        {
            name: "Murilay",
            geschlecht: "weiblich",
            birthdate: "11.09.2016",
            father: "Grand",
        },
        {
            name: "Lot of Patience",
            geschlecht: "weiblich",
            birthdate: "14.04.2014",
            father: "Grand",
        },
        {
            name: "Toki",
            geschlecht: "weiblich",
            birthdate: "31.03.2012",
            father: "Grand",
        },

    ];

    const p2 = [
        {
            name: "Whippet",
            geschlecht: "weiblich",
            birthdate: "19.08.2019",
            father: "Grand",
        },
        {
            name: "Mountain Rush",
            geschlecht: "männlich",
            birthdate: "01.11.2017",
            father: "Grand",
        },
        {
            name: "Annie Oakley",
            geschlecht: "weiblich",
            birthdate: "08.10.2015",
            father: "Grand",
        },
        {
            name: "Zonda",
            geschlecht: "männlich",
            birthdate: "24.12.2013",
            father: "Grand",
        },
        {
            name: "Proudly",
            geschlecht: "weiblich",
            birthdate: "30.05.2011",
            father: "Grand",
        },
    ];

    const p3 = [
        {
            name: "Diskus",
            geschlecht: "männlich",
            birthdate: "29.09.2019",
            father: "Grand",
        },
        {
            name: "Angel",
            geschlecht: "weiblich",
            birthdate: "11.12.2017",
            father: "Grand",
        },
        {
            name: "Dermot",
            geschlecht: "männlich",
            birthdate: "13.02.2015",
            father: "Grand",
        },
        {
            name: "Lady Lissert",
            geschlecht: "weiblich",
            birthdate: "30.12.2012",
            father: "Grand",
        },
        {
            name: "Lillimae",
            geschlecht: "weiblich",
            birthdate: "01.01.2011",
            father: "Grand",
        },
    ];

    return (
        <div>
            <div className={classes.root}></div>

            <div>
                <h3>Zuchtstute: Redouane</h3>
                <img className="p1" src={qrcode} alt="Redouane" />
                <div className="bold">
                    <Grid container spacing={0}>
                        <Grid item xs>
                            Name
            </Grid>
                        <Grid item xs>
                            Geschlecht
            </Grid>
                        <Grid item xs>
                            Geburtsdatum
            </Grid>
                        <Grid item xs alignItems="center">
                            Vater
            </Grid>
                    </Grid>
                </div>
                <div className="small">
                    {p1.map((school, index) => (
                        <StammComp horse={school} />
                    ))}{" "}
                </div>
            </div>
            <div>
                <h3>Zuchtstute: Ucintha</h3>
                <img className="p1" src={pzwei} alt="Elspeth" />
                <div className="bold">
                    <Grid container spacing={0}>
                        <Grid item xs>
                            Name
            </Grid>
                        <Grid item xs>
                            Geschlecht
            </Grid>
                        <Grid item xs>
                            Geburtsdatum
            </Grid>
                        <Grid item xs alignItems="center">
                            Vater
            </Grid>
                    </Grid>
                </div>
                <div className="small">
                    {p2.map((school, index) => (
                        <StammComp horse={school} />
                    ))}{" "}
                </div>
            </div>
            <div>
                <h3>Zuchtstute: Elspeth</h3>
                <img className="p1" src={pdrei} alt="Ucintha" />
                <div className="bold">
                    <Grid container spacing={0}>
                        <Grid item xs>
                            Name
            </Grid>
                        <Grid item xs>
                            Geschlecht
            </Grid>
                        <Grid item xs>
                            Geburtsdatum
            </Grid>
                        <Grid item xs alignItems="center">
                            Vater
            </Grid>
                    </Grid>
                </div>
                <div className="small">
                    {p3.map((school, index) => (
                        <StammComp horse={school} />
                    ))}{" "}
                </div>
            </div>
        </div>
    );
}
