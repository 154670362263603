import React, { useContext, useState } from "react";
import Badge from "@material-ui/core/Badge";
import AppContext from "../providers/AppContext";
import Home from "./Home";
import Stammbaum from "./Stammbaum";

export default function Start() {
  const [post, setPost] = useState("start");
  const { open } = useContext(AppContext);
  return (
    <div className="centering centerios">
      <div className="orders-filter card-title-class">
        <span
          onClick={() => setPost("start")}
          className={post === "start" ? "filter-underlined" : ""}
        >
          <Badge badgeContent={0} color="secondary">
            Start
          </Badge>
        </span>
        <span
          onClick={() => setPost("stammbaum")}
          className={post === "stammbaum" ? "filter-underlined" : ""}
        >
          <Badge badgeContent={0} color="secondary">
            Stammbaum
          </Badge>
        </span>
      </div>
      {post === "start" ? <Home /> : <Stammbaum />}
    </div>
  );
}
