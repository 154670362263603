import React, { useState } from "react";
import StammComp from "./StammComp";
import { makeStyles } from "@material-ui/core/styles";
import eltern1 from "../e1.jpg";
import eltern2 from "../e2.jpg";



import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));

export default function Eltern() {
    const classes = useStyles();
    const e1 = [
        {
            name: "Ucintha",
            geschlecht: "weiblich",
            birthdate: "26.11.2006",
            father: "Bojan",
        },
        {
            name: "Redouane",
            geschlecht: "weiblich",
            birthdate: "11.09.2004",
            father: "Bojan",
        },
        {
            name: "Sobella",
            geschlecht: "weiblich",
            birthdate: "19.06.2001",
            father: "Glenny",
        },
    ];

    const e2 = [
        {
            name: "Elspeth",
            geschlecht: "weiblich",
            birthdate: "21.01.2007",
            father: "Glenny",
        },
        {
            name: "Chicuelo",
            geschlecht: "männlich",
            birthdate: "06.07.2004",
            father: "Glenny",
        },
        {
            name: "Yacata",
            geschlecht: "weiblich",
            birthdate: "03.01.2003",
            father: "Bojan",
        },
        {
            name: "Scanner",
            geschlecht: "männlich",
            birthdate: "01.10.2000",
            father: "Lucky",
        },
        {
            name: "Whip",
            geschlecht: "männlich",
            birthdate: "03.10.1998",
            father: "Lucky",
        },
    ];


    return (
        <div>
            <div className={classes.root}></div>

            <div>
                <h3>Zuchtstute: Brynja</h3>
                <img className="p1" src={eltern2} alt="Redouane" />
                <div className="bold">
                    <Grid container spacing={0}>
                        <Grid item xs>
                            Name
            </Grid>
                        <Grid item xs>
                            Geschlecht
            </Grid>
                        <Grid item xs>
                            Geburtsdatum
            </Grid>
                        <Grid item xs alignItems="center">
                            Vater
            </Grid>
                    </Grid>
                </div>
                <div className="small">
                    {e1.map((school, index) => (
                        <StammComp horse={school} />
                    ))}{" "}
                </div>
            </div>
            <div>
                <h3>Zuchtstute: Minnette</h3>
                <img className="p1" src={eltern1} alt="Elspeth" />
                <div className="bold">
                    <Grid container spacing={0}>
                        <Grid item xs>
                            Name
            </Grid>
                        <Grid item xs>
                            Geschlecht
            </Grid>
                        <Grid item xs>
                            Geburtsdatum
            </Grid>
                        <Grid item xs alignItems="center">
                            Vater
            </Grid>
                    </Grid>
                </div>
                <div className="small">
                    {e2.map((school, index) => (
                        <StammComp horse={school} />
                    ))}{" "}
                </div>
            </div>

        </div>
    );
}
