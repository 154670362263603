import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function StammComp(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs>
          {props.horse.name}
        </Grid>
        <Grid item xs>
          {props.horse.geschlecht}
        </Grid>
        <Grid item xs alignItems="center">
          {props.horse.birthdate}
        </Grid>
        <Grid item xs>
          {props.horse.father}
        </Grid>
      </Grid>

      <hr />
    </div>
  );
}
