import React, { useState } from "react";
import AppContext from "./AppContext";

const AllProv = ({ children }) => {
  const [open, setOpen] = useState(false);

  const context = {
    open,
    setOpen,
  };

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

export default AllProv;
