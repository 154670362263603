import React, { useState } from "react";

import werbung from "../werbebanner.jpg";
import Generation from "./Generation";
import Eltern from "./Eltern";
import { useHistory } from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";



export default function Stammbaum() {
  const [auf, setAuf] = useState(false);
  const [zauf, setZauf] = useState(false);
  const history = useHistory();
  function handleClick() {
    history.push("/impressum");
  }

  function aufm(auf) {
    if (zauf === true) {
      setAuf(!auf);
      setZauf(false);
    } else { setAuf(!auf); }
  }


  function zaufm(zauf) {
    if (auf === true) {
      setZauf(!zauf);
      setAuf(false);
    } else { setZauf(!zauf); }
  }


  return (
    <div>

      <div className="wbanner">
        <a
          href="https://tillreiten.web.app"
          target="tillreiten.web.app.com"
          rel="noopener noreferrer"
        >
          <img className="start-img centering" src={werbung} alt="Handyscreen" />
        </a>
      </div>
      <p className="reihe">{!auf ? (
        <ArrowForwardIosIcon fontSize="inherit" color="disabled" />
      ) : <div className="viz"><ArrowForwardIosIcon fontSize="inherit" color="disabled" /></div>}
        {!auf ? <span onClick={() => aufm(auf)} className="genz">1. Generation</span> : <span onClick={() => aufm(auf)} className="genzg">1. Generation</span>}</p>


      <p className="reihe">{!zauf ? (
        <ArrowForwardIosIcon fontSize="inherit" color="disabled" />
      ) : <div className="viz"><ArrowForwardIosIcon fontSize="inherit" color="disabled" /></div>}
        {!zauf ? <span onClick={() => zaufm(zauf)} className="genz">2. Generation</span> : <span onClick={() => zaufm(zauf)} className="genzg">2. Generation</span>}</p>
      <div>
        {auf ?
          <Eltern /> : null}
      </div>
      <div> {zauf ?
        <Generation /> : null}
      </div>
      {auf || zauf ? <p onClick={handleClick}>Impressum</p> : <p className="impress" onClick={handleClick}>Impressum</p>}

    </div>

  );
}
